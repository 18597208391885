import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Masonry from 'react-masonry-css'
import { StaticImage } from 'gatsby-plugin-image'

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
}

const ImageWrapper = ({ children, description }) => (
  <div className="image-element-class">
    {children(description)}
    <p>
      <small className="small">{description}</small>
    </p>
  </div>
)

const Gallery = () => (
  <Layout>
    <Helmet>
      <title>
        Élagage // Abattage // Arboriste // Grimpeur-Élagueur, taille et soins
        des arbres // Belgique
      </title>
      <meta
        name="description"
        content="Grimpeur-élagueur à Bruxelles, technicien spécialisé dans la taille raisonnée et le soin des arbres."
      />
    </Helmet>

    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>Galerie</h1>
        </header>
        <div className="content">
          <p>
            Un professionnel qui vit son métier avec passion, <br />
            qu’il met au service des arbres.
          </p>
        </div>
      </div>
    </section>

    <div id="main">
      <div className="inner">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <ImageWrapper description="Suppression de bois mort dans un Fraxinus Excelsior ( Frêne commun ) à Oteppe">
            {(description) => (
              <StaticImage
                src="../assets/images/elagage-fraxinus-excelsior-frene-commun-oteppe-liege.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Évacuation de vos déchets organiques dans les centres appropriés">
            {(description) => (
              <StaticImage
                src="../assets/images/evacuation-dechets-vert-organique.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Abattage par démontage d’un Aesculus hippocastanum ( Marronnier d’Inde ) à Schaerbeek">
            {(description) => (
              <StaticImage
                src="../assets/images/abattage-aesculus-hippocastanum-marronnier-d_Inde-schaerbeek-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Plantation d’un Nyssa sylvatica ( Tupélo ) à Watermael-Boitsfort">
            {(description) => (
              <StaticImage
                src="../assets/images/plantation-nyssa-sylvatica-tupelo-watermael-boitsfort-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Suppression ancienne conduite à la carrière de Sprimont">
            {(description) => (
              <StaticImage
                src="../assets/images/suppression-ancienne-conduite-carriere-sprimont-liege.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Taille de cohabitation d’un Fraxinus excelsior ( Frêne commun ) à Ixelles">
            {(description) => (
              <StaticImage
                src="../assets/images/taille-fraxinus-excelsior-frene-ixelles-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Abattage par démontage de divers résineux à Watermael-Boitsfort">
            {(description) => (
              <StaticImage
                src="../assets/images/abattage-resineux-watermael-boitsfort-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Abattage par démontage d’un Pinus sylvestris ( Pin sylvestre ) à La Hulpe">
            {(description) => (
              <StaticImage
                src="../assets/images/abattage-pinus-sylvestris-pin-sylvestre-la-hulpe.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Création et entretien de jardin à Woluwe-Saint-Lambert">
            {(description) => (
              <StaticImage
                src="../assets/images/creation-entretien-de-jardin-a-woluwe-saint-lambert-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Évacuation d’un Fagus sylvatica ( Hêtre commun ) tombé lors de grand vent dans un étang à Berchem-Saint-Agathe">
            {(description) => (
              <StaticImage
                src="../assets/images/evacuation-fagus-sylvatica-hetre-commun-a-berchem-saint-agathe-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Suppression de Bois mort dans un Quercus rubra ( Chêne rouge d’Amérique ) à Grez-Doiceau">
            {(description) => (
              <StaticImage
                src="../assets/images/elagage-quercus-rubra-chene-rouge-d_amerique-grez-doiceau.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Taille de restructuration d’un Fagus sylvatica ( Hêtre commun ) à Laeken">
            {(description) => (
              <StaticImage
                src="../assets/images/taille-fagus-sylvatica-hetre-commun-laeken-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Création de jardin Avant - Après">
            {(description) => (
              <StaticImage
                src="../assets/images/creation-de-jardin-avant-apres.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Abattage par démontage d’un Aesculus hippocastanum ( Marronnier d’Inde ) à la Clinique Sans Souci à Jette">
            {(description) => (
              <StaticImage
                src="../assets/images/abattage-aesculus-hippocastanum-marronnier-d_inde-jette-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Abattage par démontage d’un Fagus syvatica  ( Hêtre pourpre ) à Ittre">
            {(description) => (
              <StaticImage
                src="../assets/images/abattage-fagus-syvatica-hetre-pourpre-ittre.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Suppression d’un Hedera Helix ( Lierre grimpant ) dans Robinia pseudoacacia ( Robinier faux-acacia ) à Drogenbos">
            {(description) => (
              <StaticImage
                src="../assets/images/suppression-hedera-helix-lierre-grimpant-dans-robinia-pseudoacacia-robinier-faux-acacia-drogenbos.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Abattage par démontage de plusieurs Picea Abies ( Epicea commun ) à Auderghem">
            {(description) => (
              <StaticImage
                src="../assets/images/abattage-picea-abies-epicea-commun-auderghem-bruxelles.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Au service des arbres...">
            {(description) => (
              <StaticImage
                src="../assets/images/image14.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="Sécurisation voirie dans un Platane">
            {(description) => (
              <StaticImage
                src="../assets/images/image15.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
          <ImageWrapper description="A votre service !">
            {(description) => (
              <StaticImage
                src="../assets/images/image3.jpeg"
                alt={description}
              />
            )}
          </ImageWrapper>
        </Masonry>
      </div>
    </div>
  </Layout>
)

export default Gallery
